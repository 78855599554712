.service-list{margin-top:70px}
.loader-container{padding-top: 20%;}
.loader-box{position: absolute;
    top: 5%;
    text-align: center;
    padding-top: 5%;
    left: 45%;width: 10%;}
    .product-icon{width: 50px !important; height: 50px;}
    .actual-price{color: black}
    .ser-btn:hover{text-decoration: none}
    .ser-btn{ text-transform: uppercase; text-decoration: none}
    .ser-btn.active{color: #fff}
    .service-list li, .service-list li:hover{ list-style: none}
    .category-tab{display: inline-block }
    .cart-final-tab{display: inline-block; float:right; }
    .checkout-btn{margin-top:5%;}