	/*
  	Flaticon icon font: Flaticon
  	Creation date: 14/01/2018 16:52
  	*/

@font-face {
  font-family: "Flaticon";
  src: url('../fonts/Flaticon.eot');
  src: url('../fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Flaticon.woff') format('woff'),
       url('../fonts/Flaticon.ttf') format('truetype'),
       url('../fonts/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url('../fonts/Flaticon.svg#Flaticon') format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-fashion-1:before { content: "\f100"; }
.flaticon-baby-dress:before { content: "\f101"; }
.flaticon-shirt:before { content: "\f102"; }
.flaticon-jacket-1:before { content: "\f103"; }
.flaticon-shorts:before { content: "\f104"; }
.flaticon-theatre-curtains:before { content: "\f105"; }
.flaticon-bed:before { content: "\f106"; }
.flaticon-sleeping-bag:before { content: "\f107"; }
.flaticon-blanket:before { content: "\f108"; }
.flaticon-dress:before { content: "\f109"; }
.flaticon-fashion:before { content: "\f10a"; }
.flaticon-skirt:before { content: "\f10b"; }
.flaticon-life-vest:before { content: "\f10c"; }
.flaticon-jeans:before { content: "\f10d"; }
.flaticon-jacket:before { content: "\f10e"; }
.flaticon-polo-shirt:before { content: "\f10f"; }
.flaticon-transport:before { content: "\f110"; }
.flaticon-shipped:before { content: "\f111"; }
.flaticon-iron:before { content: "\f112"; }
.flaticon-gesture:before { content: "\f113"; }
.flaticon-basket:before { content: "\f114"; }
.flaticon-spot:before { content: "\f115"; }
.flaticon-washing-machine-for-laundry:before { content: "\f116"; }
.flaticon-business:before { content: "\f117"; }
.flaticon-curriculum-vitae:before { content: "\f118"; }
.flaticon-clean:before { content: "\f119"; }
.flaticon-placeholder:before { content: "\f11a"; }
.flaticon-technology:before { content: "\f11b"; }
.flaticon-quality:before { content: "\f11c"; }