/* use font link */
@import url('https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300i,400,400i,600,600i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i,900,900i');
/* use style sheet */

@import url(./assets/css/theme-2.css);
@import url(./assets/css/font-awesome.css);
@import url(./assets/css/flaticon.css);
@import url(./assets/css/animate.css);
@import url(./assets/css/owl.css);
@import url(./assets/css/bootstrap.css);
/* @import url(./assets/css/bootstrap.min.css); */
@import url(./assets/css/hover.css);
/*** 
====================================================================
  Reset
====================================================================
 ***/
* {
  margin:0px;
  padding:0px;
  border:none;
  outline:none;
}
/*** 
====================================================================
  Global Settings
====================================================================
 ***/
body {
  font-size:15px;
  color:#888888;
  line-height:26px;
  font-weight:400;
  background:#ffffff;
  font-family: 'Open Sans', sans-serif;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center top;
  -webkit-font-smoothing: antialiased;
}

@media (min-width:1200px) {
  .container {
    padding: 0px 0px;
  }
}

.auto-container{
  position:static;
  max-width:1200px;
  padding:0px 15px;
  margin:0 auto;
}

.small-container{
  max-width:680px;
  margin:0 auto;
}


.centred{
  text-align: center;
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}

.boxed_wrapper{
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow-x: hidden;
}


a{
  text-decoration:none;
  position: relative;
  font-size: 15px;
  font-family: 'Open Sans',sans-serif;
  line-height: 26px;
  font-weight: 400;
  cursor:pointer;
  color:#888888;
  transition: all 500ms ease;
}

a:hover{
  text-decoration:none;
  outline:none;
  color: #6c96ff;
  transition: all 500ms ease;
}

input,button,select,textarea{
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.in-block li{
  display: inline-block;
}

ul,li{
  list-style:none;
  padding:0px;
  margin:0px; 
}

input{
  transition: all 500ms ease;
}
input:focus,
textarea:focus{
  outline: none;
  border-color: #f58123;
  transition: all 500ms ease;
}

p{
  position: relative;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  line-height: 26px;
  font-weight: 400;
  color: #888888;
  margin-bottom: 10px;
  font-style: normal;
  transition: all 500ms ease;
}

h1,h2,h3,h4,h5,h6 {
  position:relative;
  font-family: 'Poppins', Sans-serif;
  font-weight: 400;
  margin: 0px;
  color: #222222;
}
h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {
  position:relative;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin: 0px;
  color: #222222;
  transition: all 500ms ease;
}

h1{
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  color: #fff;
}

h2{
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}

h3{
  font-size: 25px;
  line-height: 34px;
  font-weight: 600;
}

h4{
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}
 

h3 a{
  font-size: 25px;
  font-weight: 600;
}
h4 a{
  font-size: 18px;
  font-weight: 600;
}


h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover{
  color: #6c96ff;
  transition: all 500ms ease;
}

.centred{
  text-align:center;  
}
.float-right{
  float: right;
}

.sp-one{
  padding: 90px 0px 100px 0px;
}




/** button **/

.btn-one {
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  padding: 1px 22px 2px 23px;
  color: #fff;
  position: relative;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border-radius: 2px;
  z-index: 1;
}
.btn-one:before {
  background-color: #000000;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  border-radius: 5px;
  top: 0;
  transform: scale(0.5, 1);
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: -1;
}

.btn-one:hover::before{
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.btn-bg{
  background: #6c96ff; 
  border: 2px solid #6c96ff;
}
.btn-one:hover{
  color: #6c96ff;
}



.page-pagination{
  position: relative;
  display: inline-block;
  margin-top: 0px;
}
.page-pagination li{
  display: inline-block;
  margin: 0px 3px;
}
.page-pagination li a{
  position: relative;
  display: inline-block;
  font-size: 14px;
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  font-family: 'Lato', sans-serif;
  color: #222;
  border-radius: 2px;
  transition: all 500ms ease;
}
.page-pagination li a:hover,
.page-pagination li a.active{
  background: #82a5fd;
  color: #fff;
  transition: all 500ms ease;
}


.preloader{
  position: fixed; 
  left:0px;
  top:0px;
  height:100%;
  width: 100%;
  z-index:999999;
  background-color:#ffffff;
  background-position:center center;
  background-repeat:no-repeat;
  background-image:url(./assets/images/icons/preloader.gif);
}

.scroll-to-top span{
  color: #fff;
}
.scroll-to-top{
  position:fixed;
  bottom:15px;
  right:15px;
  font-size:18px;
  line-height:38px;
  width:40px;
  height:40px;
  background: #222222;
  border: 2px solid #222222;
  text-align:center;
  z-index:100;
  cursor:pointer;
  display:none;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
.scroll-to-top:hover{
  background: transparent;
}
.scroll-to-top:hover span{
  color: #222222;
}
.scroll-to-top:after {
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
  background: -webkit-radial-gradient(center ellipse, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}

.sec-title h2{
  position: relative;
  display: block;
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  color: #222;
  line-height: 50px;
  font-weight: 600;
  margin-bottom: 13px;
}

.sec-title span{
  color: #6c96ff;
}
.title-text{
  line-height: 24px;
  margin-bottom: 58px;
}
.white{
  color: #fff;
}
.white h2{
  color: #ffffff;
}



/*=============== Style switcher =============*/

.switcher .switch_btn button {
  width:35px;
  height:35px;
  line-height: 35px;
  border:none;
  background: #82a5fd;
  color:#fff;
  font-size: 20px;
}
.switcher .switch_btn {
  position: absolute;
  right: -35px;
  z-index: -1;
}
.switcher .switch_btn i.fa{
  font-size: 15px;
}
.switcher {
  position: fixed;
  top: 350px;
  z-index: 999999;
}

.switcher .switch_menu {
  width:280px;
  background: #fff;
  display: none;
  position: relative;
  border: 1px solid #222222;
/*  height: 300px;
  overflow: hidden;
  overflow-y: scroll;*/
}
.switcher .switch_menu .switcher_container {
  width:270px;
  margin:0 auto;
}
.switcher .switch_menu h4.title {
  color:#fff;
  margin:0;
  line-height: 60px;
  background: #222222;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.switcher .switch_menu .switcher_container h5 {
  text-align: center;
  color:#666666;
  font-weight: 500;
  margin-bottom: 10px;
}
.switcher .switch_menu .text{
  font-size: 12px;
  text-align: center;
  margin-bottom: 25px;
}
#styleOptions{
  text-align: center;
}
.switcher #styleOptions li {
  display: inline-block;
  width:40px;
  height:40px;
  margin-right:2px;
}
.switcher #styleOptions li a {
  display: block;
  width:100%;
  height:100%;
}

/*** 

====================================================================
                        Home-Page
====================================================================

***/

/** main-menu **/


.menu-area{
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  z-index: 9;
}
.theme_menu{
  margin-bottom: -33px;
}
.menu-area:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 1000%;
  height: 100%;
  top: 0px;
  left: 45px;
}
.stricky-fixed .menu-area{
  margin-bottom: 0px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.stricky-fixed .menu-area:before{
  display: none;
}
.menu-area .top-btn{
  position: relative;
  display: inline-block;
  float: right;
}
.main-menu {
  position: relative;
  display: inline-block;
  margin-left: 34px;
}

.navbar-collapse {
  padding: 0px
}

.navigation {
  position: relative;
}
.navigation > li {
  display: inline-block;
  margin: 0px 24px 0px 24px;
}
.main-menu li.dropdown{
  position: relative;
}

.main-header li.dropdown.current:before{
  color: #f3a28b;
}
.navigation > li > a {
  color: #6c96ff;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  padding: 21px 0px 18px 0px;
  font-family: 'Poppins', sans-serif;
  transition: all 500ms ease 0s;
}


.navigation > li > ul {
  position: absolute;
  right: auto;
  top: 100%;
  z-index: 999;
  display: none;
}

.navigation > li > ul > li {
  position: relative;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 3px;
  padding: 5px 0px;
  width: 230px;
  border-radius: 3px;
  transition: all 500ms ease;
}
.navigation > li > ul > li:hover > a {
  color: #fff;
}
.navigation > li > ul > li:hover{
  background: #6c96ff;
  transition: all 500ms ease;
}

.navigation > li > ul > li > a {
  color: #6c96ff;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
  padding: 12px 0px;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
}
.hidden-bar .navigation > li{
  display: block;
}

.navigation > li > ul > li:last-child > a {
  border-bottom: 0px;
}




/*========= mobile-menu ==============*/

.mobile-menu .dropdown-btn {
  position: absolute;
  right: 15px;
  top: 0;
  width: 30px;
  height: 42px;
  border-left: 1px solid #ffffff;
  cursor: pointer;
  display: block;
  z-index: 5;
}
.mobile-menu .dropdown-btn:after {
  position: absolute;
  content: "\f107";
  font-family: FontAwesome;
  color: #fff;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  left: 7px;
}

.mobile-menu,
.mobile-menu > li > ul,
.mobile-menu > li > ul > li > ul{
  background: #000000;
}

.mobile-menu > li > ul,
.mobile-menu > li > ul > li > ul{
  display: none;
}

.mobile-menu > li > a,
.mobile-menu > li > ul > li > a,
.mobile-menu > li > ul > li > ul > li > a{
  padding:10px 20px;
  font-family: 'Roboto', sans-serif;
  line-height:22px;
  color:#ffffff;
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  border-top: 1px solid #fff;
}


/* Mainmenu fixed style */
@keyframes menu_sticky {
  0%   {margin-top:-100px;}
  50%  {margin-top: -90px;}
  100% {margin-top: 0;}
}
.theme_menu.stricky-fixed {
  margin: 0;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 99999;
  animation-name: menu_sticky;
  animation-duration: 0.60s;
  animation-timing-function: ease-out;
}


/** Main Header Style **/


.main-header{
  background: #6c96ff;
}
.logo-box{
  background: url(./assets/images/logo_new.png) no-repeat 9% 100%;
  height: 85px;
  width: 255px;
  display: inline-block;
  margin-top: -5px;
}
.logo-box a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.logo-area{
  padding: 45px 0px 54px 0px;
}
.header-top{
  padding: 17px 0px 1px 0px;
}
.header-top .header-info{
  float: right;
}
.header-top .header-info .single-info{
  position: relative;
  display: inline-block;
  margin-left: 76px;
  padding: 0px 0px 0px 56px;
}
.header-top .header-info .single-info .text{
  font-size: 13px;
  color: #fff;
  line-height: 20px;
}
.header-top .header-info .top-btn{
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 25px;
}
.header-top .header-info .top-btn a{
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  color: #6c96ff;
  text-transform: uppercase;
  background: #fff;
  padding: 4px 17px 5px 17px;
  border-radius: 5px;
  text-align: center;
  transition: all 500ms ease;
}
.header-top .header-info .top-btn a:hover{
  background: #222;
  color: #ffffff;
}
.header-top .header-info .single-info .icon-box{
  position: absolute;
  left: 0px;
  top: -5px;
  width: 40px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 3px;
}
.header-top .header-info .single-info:nth-child(2) .icon-box{
  top: 3px;
}
.header-top .header-info .single-info:nth-child(2){
  top: 12px;
}

.header-top .header-info .single-info .icon-box i:before{
  font-size: 20px;
  margin: 0px;
  color: #fff;
}

/** search box **/

.search-box-area{
  position: relative;
  top: 10px;
  right: 12px;
  float: right;
}
.search-box-area .search-toggle{
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  transition: all 500ms ease;
}
.search-box-area .search-toggle:hover{
  background: #82a5fd;
  border: 1px solid #82a5fd;
}
.search-box-area .search-toggle i:before{
  position: relative;
  font-size: 20px;
  color: #6c96ff;
  transition: all 500ms ease;
}
.search-box-area .search-toggle:hover i:before{
  color: #ffffff;
}

.search-box-area .search-box {
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 55px;
  transition: all 500ms ease 0s;
  visibility: hidden;
  z-index: 9;
}
.search-box-area .form-group{
  position: relative;
}
.search-box-area .form-group button{
  position: absolute;
  top: 2px;
  right: 0px;
  width: 46px;
  height: 46px;
  color: #fff;
  background: #6c96ff;
  border: none;
  z-index: 10;
}
.search-box-area input[type='search']{
  width: 250px;
  height: 50px;
  border: 2px solid #6c96ff;
  padding: 10px 60px 10px 15px;
  z-index: 9;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.30);
}
.search-box.now-visible {
  top: 55px;
  right: 0px;
  opacity: 1;
  visibility: visible;
  z-index: 9;
}


/** main slider **/

.main-slider {
  min-height: 100%;
}
.main-slider .container-fluid {
  padding: 0px;
}
.main-slider .slider-wrapper {
  position: relative;
}
.main-slider .slider-wrapper .image img {
  min-height: 704px;
  width: 100%;
  max-width: none;
}
.main-slider .slider-caption {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}
.main-slider .slider-caption .inner-box {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}
.main-slider .slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 3;
}
.main-slider .text{
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #fff;
  line-height: 22px;
  max-width: 700px;
  margin-bottom: 35px;
}
.main-slider .title span{
  color: #6c96ff;
}
.main-slider .title h1{
  position: relative;
  max-width: 800px;
  margin-bottom: 20px;
}

/* slide nav */

.slide-nav .owl-nav {
  padding: 0px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  transform: scale(0.97);
  margin-top: 0px;
  transition: .5s ease;
}
.slide-nav:hover .owl-nav {
  transform: scale(1);
  opacity: 1;
}
.slide-nav .owl-nav [class*="owl-"] {
  position: relative;
  width: 32px;
  height: 80px;
  line-height: 80px;
  background:  #ffffff;
  text-align: center;
  font-size: 20px;
  color: #82a5fd;
  border-radius: 0px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}
.slide-nav .owl-nav [class*="owl-"]:hover{
  box-shadow: none;
  background: #222222;
}
.slide-nav .owl-nav [class*="owl-"]:hover span{
  color: #82a5fd;
}
.slide-nav .carousel-outer:hover .owl-nav .owl-next,
.slide-nav .carousel-outer:hover .owl-nav .owl-prev {
  opacity: 1;
  visibility: visible;
}
.slide-nav .owl-nav .owl-prev {
  left: 15px;
  float:  left;
}
.slide-nav .owl-nav .owl-next {
  right: 15px;
  float:  right;
}

.slide-nav .owl-dots{
  display: none !important;
}

/* slide animation */
.main-slider .owl-item .slider-caption * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.main-slider .owl-item.active .slider-caption h1 {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.main-slider .owl-item.active .slider-caption .text {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.main-slider .owl-item.active .slider-caption .tp-btn {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}


/** working-section **/


.working-section{
  position: relative;
}
.working-section:before{
  position: absolute;
  content: '';
  background: url(./assets/images/shap.png);
  width: 540px;
  height: 740px;
  left: 0px;
  top: 0px;
}
.single-working-content{
  position: relative;
  background: #f7f7f7;
  padding: 34px 0px 0px 0px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}
.single-working-content .icon-box{
  position: relative;
  display: inline-block;
  width: 130px;
  height: 130px;
  line-height: 160px;
  border-radius: 50%;
  background: #fff;
  margin-bottom: 58px;
  box-shadow: 0 0 20px 1px rgba(225, 225, 225, 0.8);
}
.single-working-content .icon{
  position: relative;
  display: inline-block;
  width: 102px;
  height: 102px;
  line-height: 104px;
  border-radius: 50%;
  border: 1px solid #f8f8f8;
  background: #fff;
  box-shadow: 0 0 20px 1px rgba(225, 225, 225, 0.5);
  transition: all 500ms ease;
}
.single-working-content:hover .icon{
  background: #6c96ff;
  transition: all 500ms ease;
  -webkit-animation: pulse 1s infinite;
  -o-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}
.single-working-content:hover .icon-box i:before{
  color: #fff;
}
.single-working-content .icon-box i:before{
  font-size: 50px;
  color: #818181;
  margin: 0px;
  transition: all 500ms ease;
}
.single-working-content h3{
  margin-bottom: 29px;
}
.single-working-content .count-text{
  position: relative;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #6c96ff;
  text-align: center;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #fff;
  border-radius: 50%;
  bottom: -23px;
  box-shadow: 0 5px 10px 0px #ccc;
  transition: all 500ms ease;
}

/** choose-section **/


.choose-section{
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  padding: 90px 0px;
}
.choose-section:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.5;
}
.single-choose-content{
  position: relative;
  background: #fff;
  padding: 48px 30px 24px 30px;
  border-radius: 10px;
}
.single-choose-content .icon-box{
  margin-bottom: 16px;
}
.single-choose-content .icon-box i:before{
  font-size: 45px;
  color: #888888;
  margin: 0px;
  transition: all 500ms ease;
}
.single-choose-content:hover .icon-box i:before{
  color: #82a5fd;
}
.single-choose-content h3{
  margin-bottom: 18px;
  color: #6c96ff;
}
.choose-section .title-text{
  position: relative;
  margin-bottom: 69px;
}
.owl-nav-none .owl-nav{
  display: none;
}
.owl-dots{
  margin-top: 36px;
}


/** service-section **/


.service-section{
  padding: 93px 0px 95px 0px;
}
.service-section .service-btn{
  float: right;
  position: relative;
  margin-top: -41px;
}
.service-section .service-btn a{
  position: relative;
  color: #222222;
  font-weight: 600;
  text-decoration: underline;
}
.service-section .service-btn a:hover{
  color: #82a5fd;
}
.single-item-hoverly .img-box {
  position: relative;
  overflow: hidden;
}
.single-item-hoverly .img-box img{
  width: 100%;
}
.single-item-hoverly:hover .overlay {
  height: 100%;
  transition: .5s cubic-bezier(0.4, 0, 1, 1);
  opacity: 1;
  visibility: visible;
}

.single-item-hoverly .overlay {
  position: absolute;
  text-align: center;
  left: 0px;
  align-items: center;
  justify-content: center;
  top: 0px;
  width: 100%;
  display: flex;
  height: 0%;
  background: rgba(108, 150, 255, 0.40);
  transition: .5s cubic-bezier(0.4, 0, 1, 1);
  opacity: 0;
  visibility: hidden;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.single-service-content{
  position: relative;
  margin-bottom: 78px;
  box-shadow: 0px 3px 10px 2px rgba(204, 204, 204, 0.5);
  border-radius: 10px;
}
.single-service-content .lower-content{
  padding: 41px 25px 33px 25px;
}
.single-service-content .lower-content h3 a{
  display: inline-block;
  margin-bottom: 25px;
}
.lh-24{
  line-height: 24px;
}

/** fact-counter **/


.fact-counter{
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  padding: 90px 0px 100px 0px;
}
.fact-counter:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.5;
}
.fact-counter .title-text h4 {
  line-height: 30px;
}
.fact-counter .title-text{
  margin-bottom: 56px;
}
.fact-counter .sec-title{
  margin-bottom: 24px;
}
.single-counter-content{
  position: relative;
  display: block;
  background: rgba(108, 150, 255, 0.20);
  padding: 38px 0px 29px 0px;
  border-radius: 5px;
  transition: all 500ms ease;
}
.single-counter-content:hover{
  background: rgba(255, 255, 255, 0.5);
}
.single-counter-content h2{
  display: block;
  margin-bottom: 13px;
}
.single-counter-content .text h4{
  color: #6c96ff;
}


/** help-section **/


.help-section{
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 91px 0px 93px 0px;
  background-attachment: fixed;
}
.help-section:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: .7;
}
.help-section .title-text{
  position: relative;
  line-height: 25px;
  color: #888;
  margin-bottom: 67px;
}
.help-section .owl-item.active:nth-child(2) .single-help-content{
  background: #fff;
}
.owl-dots-none .owl-dots{
  display: none !important;
}
.single-help-content{
  position: relative;
  background: transparent;
  border: 1px solid #6c96ff;
  padding: 55px 30px 35px 50px;
  border-radius: 10px;
  transition: all 500ms ease;
}
.single-help-content.second-column{
  padding: 55px 30px 23px 50px;
}
.single-help-content.third-column{
  padding: 53px 30px 34px 35px;
}
.single-help-content.fourth-column{
  padding: 53px 30px 41px 50px;
}
.single-help-content .top-content{
  position: relative;
  margin-bottom: 18px;
  padding: 0px 0px 0px 0px;
}
.single-help-content.third-column .top-content{
  padding: 0px 0px 0px 30px;
  margin-bottom: 24px;
}
.single-help-content.fourth-column .top-content{
  padding: 0px 0px 0px 59px;
  margin-bottom: 17px;
}
.single-help-content.third-column .text{
  margin-bottom: 18px;
}
.single-help-content .top-content i:before{
  font-size: 40px;
  color: #6c96ff;
  margin: 0px;
}
.single-help-content.fourth-column .top-content i:before{
  font-size: 30px;
}
.single-help-content.third-column .top-content i:before{
  font-size: 55px;
}
.single-help-content.third-column .top-content .icon-box{
  top: 7px;
  left: 7px;
}
.single-help-content.fourth-column .top-content .icon-box{
  top: 4px;
  left: 14px;
}
.single-help-content .top-content .icon-box{
  position: absolute;
  left: 0px;
  top: 1px;
}
.single-help-content.second-column .top-content{
  margin-bottom: 5px;
}
.single-help-content.second-column .text{
  margin-bottom: 12px;
}
.single-help-content.first-column .text{
  line-height: 26px;
}
.single-help-content .text{
  margin-bottom: 5px;
  line-height: 25px;
}
.single-help-content .call{
  font-weight: 600;
  color: #6c96ff;
  margin-bottom: 3px;
}
.single-help-content .skype{
  position: relative;
  margin-top: 22px;
}
.single-help-content .skype a{
  position: relative;
  display: inline-block;
  height: 36px;
  width: 40px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #6c96ff;
  color: #6c96ff;
  font-size: 22px;
  border-radius: 5px;
}
.single-help-content .skype a:hover{
  background: #6c96ff;
  color: #ffffff;
}
.single-help-content .button{
  display: inline-block;
  margin-top: 19px;
}
.single-help-content .button a{
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #6c96ff;
  border: 1px solid #6c96ff;
  border-radius: 5px;
  padding: 4px 15px 6px 14px;
}
.single-help-content .button a:hover{
  background: #6c96ff;
  color: #ffffff;
}
.single-help-content.fourth-column .input-box{
  position: relative;
  display: inline-block;
  padding: 0px 1px 0px 0px;
  margin-top: 32px;
}
.form-group{
  margin-bottom: 0px;
}
.single-help-content.fourth-column .input-box input[type='email']{
  position: relative;
  width: 100%;
  height: 35px;
  border: 1px solid #6c96ff;
  border-radius: 5px;
  line-height: 35px;
  padding: 0px 20px;
  transition: all 500ms ease;
}
.single-help-content.fourth-column .input-box input[type='email']:focus{
  border: 1px solid #222;
}
.single-help-content.fourth-column .input-box input[type='email']:focus + button,
.single-help-content.fourth-column .input-box button:hover{
  background: #222;
}
.single-help-content.fourth-column .input-box button{
  position: absolute;
  top: 0px;
  right: 1px;
  color: #fff;
  font-size: 13px;
  background: #6c96ff;
  width: 39px;
  height: 35px;
  line-height: 35px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 500ms ease;
}
.single-help-content.fifth-column {
  padding: 52px 70px 59px 53px;
}
.single-help-content.fifth-column .top-content .icon-box {
  top: 5px;
}
.single-help-content.fifth-column .top-content{
  padding: 0px 0px 0px 45px;
  margin-bottom: 28px;
}
.single-help-content.fifth-column .text{
  margin-bottom: 15px;
}
.single-help-content.fifth-column .button a{
  padding: 3px 35px 4px 35px;
}


/** testimonial-section **/


.testimonial-section{
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  padding: 93px 0px 92px 0px;
}
.testimonial-section .owl-dots{
  margin-top: 41px;
}
.testimonial-section .sec-title{
  margin-bottom: 3px;
}
.testimonial-section .title-text{
  position: relative;
  margin-bottom: 54px;
}
.overlay-bg{
  position: relative;
}
.overlay-bg:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: .6;
}
.single-testimonial-content{
  position: relative;
  display: block;
  background: #fff;
  height: 259px;
  padding: 0px 25px 0px 25px;
  border-radius: 10px;
  margin-top: 43px;
}
.single-testimonial-content:before{
  position: absolute;
  content: "\f10e";
  font-family: 'FontAwesome';
  color: #f6f6f6;
  font-size: 90px;
  right: 10px;
  bottom: 30px;
}
.single-testimonial-content .img-box{
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-top: -45px;
  margin-bottom: -3px;
  width: 85px;
  height: 85px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.single-testimonial-content .img-box img{
  width: 100%;
  background: #ffffff;
  border-radius: 50%;
}
.single-testimonial-content .title{
  color: #6c96ff;
  margin-bottom: 12px;
  margin-bottom: 21px;
}
.single-testimonial-content h4{
  margin-bottom: -1px;
}
.single-testimonial-content p{
  line-height: 25px;
}


/** pricing-section **/



.single-pricing-content{
  position: relative;
  padding: 88px 10px 46px 10px;
  border: 1px solid #f6f6f6;
  border-radius: 20px;
  transition: all 500ms ease;
}
.single-pricing-content:hover{
  box-shadow: 0px 0px 20px 10px rgba(204, 204, 204, 0.3);
  z-index: 1;
}
.single-pricing-content .pricing-btn{
  display: inline-block;
  margin-top: 20px;
}
.single-pricing-content .pricing-btn a{
  position: relative;
  display: inline-block;
  padding: 5px 36px 4px 36px;
  background: #6c96ff;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  opacity: 0;
  font-family: 'Poppins', sans-serif;
  transition: all 500ms ease;
}
.single-pricing-content .pricing-btn a:hover{
  background: #222 !important;
}
.single-pricing-content:hover .pricing-btn a{
  opacity: 1;
}
.single-pricing-content .icon-box{
  margin-bottom: 14px;
}
.single-pricing-content .icon-box i:before{
  font-size: 105px;
  color: #6c96ff;
  margin: 0px;
}
.single-pricing-content .top-text{
  font-weight: 800;
  margin-bottom: 29px;
}
.single-pricing-content h3,
.single-pricing-content h2{
  display: inline-block;
}
.single-pricing-content .title{
  margin-bottom: 28px;
}
.single-pricing-content:hover h2{
  transition: all 500ms ease;
  -webkit-animation: pulse 1s infinite;
  -o-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}
.single-pricing-content .title span{
  position: relative;
  display: inline-block;
  color: #222;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.single-pricing-content .list{
  padding: 0px 30px 0px 88px;
}
.single-pricing-content .list li{
  position: relative;
  display: block;
  margin-bottom: 13px;
  padding: 0px 0px 13px 26px;
  border-bottom: 1px solid #f7f7f7;
}
.single-pricing-content .list li:before{
  position: absolute;
  content: "\f00c";
  font-size: 15px;
  font-family: 'FontAwesome';
  color: #6c96ff;
  left: 0px;
  top: 2px;
}
.pricing-column{
  padding: 0px;
}


/** loundry-apps **/


.apps-content .top-text{
  color: #6c96ff;
  margin-bottom: 8px;
}
.loundry-apps{
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}
.apps-content h2,
.apps-content h4,
.apps-content p{
  color: #fff;
}
.apps-content p{
  line-height: 25px;
}
.apps-content h2{
  margin-bottom: 23px;
  line-height: 48px;
}
.apps-content .apps-box{
  margin-top: 37px;
}
.apps-content h2 span{
  color: #6c96ff;
}
.apps-content .apps-box .single-apps-box{
  position: relative;
  display: inline-block;
  padding: 13px 28px 12px 62px;
  margin-right: 14px;
  border: 1px solid #6c96ff;
  border-radius: 5px;
  transition: all 500ms ease;
}
.apps-content .apps-box .single-apps-box:hover{
  background: #6c96ff;
}
.apps-content .apps-box .single-apps-box .icon-box{
  position: absolute;
  left: 20px;
  top: 18px;
}
.apps-content .apps-box .text{
  margin-bottom: -3px;
}

.video-gallery {
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-top: 44px;
}

.video-gallery img {
  transform: scale(1);
  transition: all 0.5s ease 0s;
  width: 100%;
}
.video-gallery .overlay-gallery {
  background-color: transparent;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  border-radius: 10px;
  transition: all 500ms ease;
}
.video-gallery .overlay-gallery .icon-holder{
  display: table;
  height: 100%;
  width: 100%;    
}
.video-gallery .overlay-gallery .icon-holder .icon{
  display: table-cell;
  vertical-align: middle;    
}
.video-gallery .overlay-gallery .icon-holder .icon a{
  position: relative;
  display: inline-block;
  font-size: 50px;
  color: #fff;
  transition: all 500ms ease;
  -webkit-animation: pulse 1s infinite;
  -o-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}
.video-gallery .overlay-gallery .icon-holder .icon a:hover{
  -webkit-animation: pulse 0s infinite;
  -o-animation: pulse 0s infinite;
  animation: pulse 0s infinite;
}
.video-gallery:hover .overlay-gallery {
  background-color: rgba(108, 150, 255, 0.50);
}


/** news-section **/


.single-news-content .overlay{
  background: rgba(255, 255, 255, 0.5);
}
.single-news-content{
  position: relative;
  padding-bottom: 17px;
  box-shadow: 0px 3px 10px 2px rgba(204, 204, 204, 0.5);
  margin-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
}
.single-news-content.first-column{
  padding-bottom: 13px;
}
.single-news-content .meta{
  position: relative;
  display: block;
  background: #6c96ff;
  padding: 13px 0px 13px 30px;
  color: #fff;
  margin-bottom: 26px;
}
.single-news-content .meta li{
  position: relative;
  margin-right: 35px;
  font-size: 13px;
}
.single-news-content .meta li:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 1px;
  height: 13px;
  top: 7px;
  right: -20px;
}
.single-news-content .meta li:last-child:before{
  display: none;
}
.single-news-content .lower-content h4{
  line-height: 21px;
}
.single-news-content .lower-content h4 a{
  display: inline-block;
  margin-bottom: 10px;
  line-height: 21px;
}
.single-news-content .lower-content p{
  line-height: 25px;
}
.single-news-content .lower-content{
  padding: 0px 20px 0px 30px;
}
.news-section .title-text{
  margin-bottom: 56px;
}
.news-section{
  padding-bottom: 91px;
}
.news-section .owl-theme .owl-controls .owl-nav{
  margin-top: 21px;
}


/** main-footer **/


.main-footer{
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.main-footer.overlay-bg:before{
  opacity: .8;
}
.main-footer ::-webkit-input-placeholder { /* Chrome */
  color: #ffffff;
}

.main-footer :-ms-input-placeholder { /* IE 10+ */
  color: #ffffff;
}
.main-footer ::-moz-placeholder { /* Firefox 19+ */
  color: #ffffff;
  opacity: 1;
}
.main-footer :-moz-placeholder { /* Firefox 4 - 18 */
  color: #ffffff;
  opacity: 1;
}
.logo-widget{
  position: relative;
  margin-top: -16px;
}
.logo-widget .footer-logo{
  margin-bottom: 1px;
}
.logo-widget .text{
  font-size: 13px;
  line-height: 20px;
}
.logo-widget .location-box{
  position: relative;
  margin-top: 28px;
  margin-bottom: 10px;
  border-bottom: 1px solid #333230;
  padding: 0px 0px 13px 26px;
}
.logo-widget .location-box .icon-box{
  position: absolute;
  left: 0px;
  top: 7px;
}
.logo-widget .location-box .icon-box i:before{
  font-size: 26px;
  color: #6c96ff;
}
.logo-widget .location-box .text{
  line-height: 24px;
}
.logo-widget .footer-social li a{
  position: relative;
  display: inline-block;
  font-size: 11px;
  color: #888888;
  height: 25px;
  width: 25px;
  line-height: 24px;
  text-align: center;
  border: 1px solid #888888;
  border-radius: 50%;
  margin-right: 32px;
}
.logo-widget .footer-social li a:hover{
  color: #6c96ff;
  border: 1px solid #6c96ff;
}
.footer-title h4{
  color: #6c96ff;
  margin-bottom: 21px;
  text-transform: uppercase;
}
.service-widget{
  margin-left: 65px;
}
.service-widget .list li a{
  position: relative;
  display: block;
  color: #fff;
  margin-bottom: 16px;
}
.service-widget .list li a:hover{
  color: #6c96ff;
}
.blog-widget .text a{
  color: #fff;
}
.blog-widget .text a:hover{
  color: #6c96ff;
}
.blog-widget .text{
  position: relative;
  border-bottom: 1px solid #313232;
  padding: 0px 0px 24px 0px;
  margin-bottom: 22px;
}
.blog-widget .footer-title h4{
  margin-bottom: 22px;
}
.instagram-widget .list{
  margin-bottom: 35px;
  margin: 0px -2px
}
.instagram-widget .list li{
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 88px;
  height: 60px;
  float: left;
  margin: 0px 2px 3px 1px;
  border-radius: 5px;
}
.instagram-widget .list li img{
  width: 100%;
  border-radius: 5px;
  transform: scale(1.0);
  transition: all 500ms ease;
}
.instagram-widget .list li:hover img{
  transform: scale(1.1);
}
.instagram-widget .footer-title h4{
  margin-bottom: 31px;
}
.instagram-widget .subscribe-widget .input-box{
  position: relative;
}
.instagram-widget .subscribe-widget .input-box input[type='email']{
  position: relative;
  width: 100%;
  height: 31px;
  background: #343433;
  border: 2px solid #343433;
  color: #ffffff;
  line-height: 31px;
  padding: 0px 45px 0px 20px;
  border-radius: 5px;
  transition: all 500ms ease;
}
.instagram-widget .subscribe-widget .input-box input[type='email']:focus{
  border: 2px solid #ffffff;
}
.instagram-widget .subscribe-widget .input-box input[type='email']:focus + button,
.instagram-widget .subscribe-widget .input-box button:hover{
  background: #ffffff;
}
.instagram-widget .subscribe-widget .input-box button{
  position: absolute;
  top: 0px;
  right: 0px;
  background: #525251;
  width: 36px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  color: #6c96ff;
  font-size: 12px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 500ms ease;
}
.instagram-widget .subscribe-widget .footer-title h4{
  margin-bottom: 2px;
}
.footer-top{
  position: relative;
  padding: 52px 0px 28px 0px;
  border-bottom: 1px solid #4c4c4c;
}
.footer-bottom{
  padding: 19px 0px 18px 0px;
}
.footer-bottom .copyright,
.footer-bottom .copyright a{
  position: relative;
  font-size: 14px;
  font-weight: 700;
  color: #6c96ff;
  text-transform: uppercase;
}
.footer-bottom .copyright a:hover{
  color: #fff;
}

** bubble animation **/

.bubble-section{
  position: relative;
}
.bubbleContainer {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
}
div[class^="bubble-"] {
  height: 1px;
  width: 1px;
  position: absolute;
  background: url(./assets/images/buble.png) no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
}
.bubble-1 {
  bottom: 0px;
  left: 60%;
  -webkit-animation: bubble-movement 4s infinite ease-in -5.7s;
  animation: bubble-movement 4s infinite ease-in -5.7s;
}
.bubble-2 {
  bottom: 0px;
  left: 90%;
  -webkit-animation: bubble-movement 4s infinite ease-in -4.94s;
  animation: bubble-movement 4s infinite ease-in -4.94s;
}
.bubble-3 {
  bottom: 0px;
  left: 20%;
  -webkit-animation: bubble-movement 4s infinite ease-in -1.2s;
  animation: bubble-movement 4s infinite ease-in -1.2s;
}
.bubble-4 {
  bottom: 0px;
  left: 40%;
  -webkit-animation: bubble-movement 4s infinite ease-in -1.58s;
  animation: bubble-movement 4s infinite ease-in -1.58s;
}
.bubble-5 {
  bottom: 0px;
  left: 80%;
  -webkit-animation: bubble-movement 4s infinite ease-in -6.31s;
  animation: bubble-movement 4s infinite ease-in -6.31s;
}

.bubble-7 {
  bottom: 0px;
  left: 10%;
  -webkit-animation: bubble-movement 4s infinite ease-in -3.37s;
  animation: bubble-movement 4s infinite ease-in -3.37s;
}
.bubble-8 {
  bottom: 0px;
  left: 70%;
  -webkit-animation: bubble-movement 4s infinite ease-in -0.66s;
  animation: bubble-movement 4s infinite ease-in -0.66s;
}
.bubble-9 {
  bottom: 0px;
  left: 30%;
  -webkit-animation: bubble-movement 4s infinite ease-in -5.81s;
  animation: bubble-movement 4s infinite ease-in -5.81s;
}
.bubble-10 {
  bottom: 0px;
  left: 95%;
  -webkit-animation: bubble-movement 4s infinite ease-in -3.98s;
  animation: bubble-movement 4s infinite ease-in -3.98s;
}
.bubble-11 {
  bottom: 0px;
  left: 41%;
  -webkit-animation: bubble-movement 4s infinite ease-in -3.98s;
  animation: bubble-movement 4s infinite ease-in -3.98s;
}

.bubble-13 {
  bottom: 0px;
  left: 50%;
  -webkit-animation: bubble-movement 4s infinite ease-in -3.98s;
  animation: bubble-movement 4s infinite ease-in -3.98s;
}

.bubble-15 {
  bottom: 0px;
  left: 58%;
  -webkit-animation: bubble-movement 4s infinite ease-in -3.98s;
  animation: bubble-movement 4s infinite ease-in -3.98s;
}

@-webkit-keyframes bubble-movement {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    height: 1px;
    width: 1px; }
  100% {
    -webkit-transform: translate3d(-50%, -280px, 0);
    transform: translate3d(-50%, -280px, 0);
    height: 75px;
    width: 75px; } }

@-moz-keyframes bubble-movement {
  0% {
    transform: translate3d(-50%, 0, 0);
    height: 1px;
    width: 1px; }
  100% {
    transform: translate3d(-50%, -280px, 0);
    height: 75px;
    width: 75px; } }

@-o-keyframes bubble-movement {
  0% {
    transform: translate3d(-50%, 0, 0);
    height: 1px;
    width: 1px; }
  100% {
    transform: translate3d(-50%, -280px, 0);
    height: 75px;
    width: 75px; } }

@keyframes bubble-movement {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    height: 1px;
    width: 1px; }
  100% {
    -webkit-transform: translate3d(-50%, -280px, 0);
    transform: translate3d(-50%, -280px, 0);
    height: 75px;
    width: 75px; } }


/*** 

====================================================================
                        About-Page
====================================================================

***/

/** page-title **/


.page-title{
  position: relative;
  width: 100%;
  padding: 147px 0px 114px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
.page-title:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.3;
}
.page-title .title h2{
  position: relative;
  display: block;
  color: #fff;
  text-transform: uppercase;
}


/** about-section **/


.about-section{
  padding: 98px 0px 100px 0px;
}
.about-section .img-box{
  border: 5px solid #ffffff;
  box-shadow: 0 0 5px #ddd;
}
.about-section img{
  width: 100%;
}
.about-section .img-box-two{
  position: relative;
  left: -110px;
  margin-top: 127px;
  border: 5px solid #82a5fd;
}
.about-section .img-box-three{
  position: relative;
  margin-top: -115px;
  left: 100px;
}
.about-content{
  position: relative;
  margin-left: -100px;
  margin-top: 2px;
}
.about-content .top-text{
  position: relative;
  color: #82a5fd;
  margin-bottom: -3px;
}
.about-content h3{
  margin-bottom: 15px;
}
.about-content .text i{
  font-weight: 600;
}
.about-content .text p{
  line-height: 25px;
}


/** team-section **/


.team-section{
  padding-bottom: 82px;
}
.single-team-content img{
  width: 100%;
}
.single-team-content .img-content{
  position: relative;
  overflow: hidden;
  margin-bottom: 17px;
}
.single-team-content .img-content .img-box{
  position: relative;
  overflow: hidden;
}
.single-team-content .img-content .img-box img{
  width: 100%;
  transform: scale(1.0);
  transition: all 500ms ease;
}
.single-team-content:hover .img-content .img-box img{
  transform: scale(1.1);
}
.single-team-content .team-social {
  position: absolute;
  bottom: -200px;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  padding: 23px 0px 22px 0px;
  transition: all 900ms ease;
}
.single-team-content:hover .team-social {
  bottom: 0px;
  transition: all 900ms ease;
}
.single-team-content .team-social li a{
  position: relative;
  display: inline-block;
  width: 34px;
  height: 33px;
  line-height: 33px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  background: #6c96ff;
  border-radius: 5px;
  margin: 0px 3px;
}
.single-team-content .team-social li a:hover{
  background: #222;
}
.single-team-content .lower-content .text{
  color: #6c96ff;
}
.single-team-content .lower-content h3 a{
  display: block;
  margin-bottom: -2px;
}
.team-section .owl-theme .owl-controls .owl-nav{
  position: absolute;
  top: -128px;
  right: 0px;
  margin-top: 0px;
}
.team-section .owl-theme .owl-controls .owl-nav .owl-prev:hover,
.team-section .owl-theme .owl-controls .owl-nav .owl-next:hover{
  background: #6c96ff;
  box-shadow: none;
}
.team-section .owl-theme .owl-controls .owl-nav .owl-prev:hover span,
.team-section .owl-theme .owl-controls .owl-nav .owl-next:hover span{
  color: #fff;
}
.team-section .owl-theme .owl-controls .owl-nav [class*="owl-"] span{
  color: #222;
}
.team-section .owl-theme .owl-controls .owl-nav .owl-prev,
.team-section .owl-theme .owl-controls .owl-nav .owl-next{
  font-size: 24px;
  height: 45px;
  width: 40px;
  line-height: 45px;
}
.lh-22{
  line-height: 22px;
}


/** our-history **/

.our-history{
  position: relative;
  background: #fff;
}
.history-content{
  position: relative;
  padding: 27px 0px 14px 0px;
}
.history-content .slider-content{
  position: relative;
  display: block;
  background: #f6f6f6;
  padding: 33px 60px 104px 69px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px rgba(204, 204, 204, 0.3);
  margin: 15px 0px;
}
.history-content{
  position: relative;
}
.history-content .img-column{
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
.history-content .slider-content h2{
  display: block;
  margin-bottom: -8px;
}
.history-content .slider-content h4{
  color: #6c96ff;
  margin-bottom: 15px;
}
.history-content .slider-content p{
  line-height: 25px;
}
.our-history .owl-theme .owl-controls .owl-nav{
  position: absolute;
  left: 69px;
  bottom: 41px;
  margin-top: 0px;
}
.our-history .owl-theme .owl-controls .owl-nav .owl-prev, 
.our-history .owl-theme .owl-controls .owl-nav .owl-next {
  background: #fff;
}
.our-history .owl-theme .owl-controls .owl-nav .owl-prev:hover, 
.our-history .owl-theme .owl-controls .owl-nav .owl-next:hover{
  background: #6c96ff;
  box-shadow: none;
}
.our-history .owl-theme .owl-controls .owl-nav .owl-prev:hover span, 
.our-history .owl-theme .owl-controls .owl-nav .owl-next:hover span{
  color: #fff;
}
.our-history .title-text{
  margin-bottom: 55px;
}

/** partner-section **/

.partner-section{
  padding: 92px 0px 93px 0px;
}
.partner-slider li{
  position: relative;
  display: inline-block;
  width: 180px;
  background: #e5e5e5;
  text-align: center;
}
.partner-slider li img{
  opacity: 0.4;
  transition: all 500ms ease;
}
.partner-slider li:hover img{
  opacity: 1;
}
.partner-section .sec-title{
  margin-bottom: 15px;
}
.partner-section .title-text{
  line-height: 25px;
  margin-bottom: 53px;
}


/*** 

====================================================================
                        Offer-Page
====================================================================

***/


/** offer-section **/


.offer-content{
  position: relative;
  background: #f7f7f7;
  padding: 40px 50px 42px 50px;
  border-radius: 10px;
}
.offer-content-three{
  padding: 40px 35px 7px 35px;
}
.offer-content h4{
  display: block;
  line-height: 25px;
  margin-bottom: 11px;
}
.offer-content .text{
  position: relative;
  display: block;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 28px;
  color: #888888;
  margin-bottom: 9px;
}
.offer-content .offer-btn{
  margin-bottom: 24px;
}
.offer-content .offer-btn a{
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #fff;
  background: #82a5fd;
  border-radius: 3px;
  padding: 8px 38px 6px 37px;
}
.offer-content .offer-btn a:hover{
  background: #222;
}
.offer-content .icon-list li a{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 43px;
  text-align: center;
  border: 1px solid #6c96ff;
  border-radius: 5px;
  margin: 0px 3px;
}
.offer-content .icon-list li a:hover{
  background: #6c96ff;
}
.offer-section .owl-carousel .owl-item img{
  display: inline-block;
}
.offer-content-two h4{
  margin-bottom: 27px;
}
.offer-content-two .text{
  margin-bottom: 12px;
}
.offer-content-two .offer-btn{
  display: inline-block;
  margin-top: 11px;
  margin-bottom: 4px;
}
.offer-content-two{
  position: relative;
}
.offer-content-two:before{
  position: absolute;
  content: "\f116";
  font-family: 'Flaticon';
  font-size: 150px;
  color: #f2f2f2;
  right: -9px;
  bottom: 62px;

}
.offer-content-three .icon-box i:before{
  font-size: 105px;
  color: #888888;
  margin: 0px;
}
.offer-content-three .offer-btn{
  margin-bottom: 50px;
}
.offer-content-three h4{
  margin-bottom: 23px;
}
.offer-section .owl-theme .owl-dots .owl-dot.active {
  border: 1px solid #222;
}
.offer-section .owl-dots {
  margin-top: 27px;
}
.offer-section{
  padding: 100px 0px 93px 0px;
}


/*** 

====================================================================
                        Faq-Page
====================================================================

***/


/** accrodian box **/

.faq-section{
  padding: 100px 0px 81px 0px;
}
.accordion {
  position: relative;
  margin-bottom: 11px;
}
.accordion-box .accordion .acc-content {
  display: none;
}
.accordion-box .accordion .acc-content.collapsed {
  display: block;
}
.accordion-box .accordion .acc-btn .title h3{
  position: relative;
  display: block;
  color: #fff;
  background: #6c96ff;
  padding: 19px 10px 16px 30px;
  cursor: pointer;
}
.accordion-box .accordion .acc-content{
  position: relative;
  box-shadow: 0 4px 13px 0px #e5e5e5ab;
  padding: 11px 30px 15px 27px;
}
.accordion-box .accordion .acc-content .text{
  line-height: 25px;
}
.faq-btn{
  position: relative;
  margin-top: 49px;
}
.faq-btn a{
  position: relative;
  display: block;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #222;
  text-decoration: underline;
}


/*** 

====================================================================
                        Error-Page
====================================================================

***/


.error-section{
  padding: 70px 0px 97px 0px;
}
.error-section .title{
  position: relative;
  font-size: 390px;
  font-family: 'Poppins', sans-serif;
  color: #6c96ff;
  font-weight: 700;
  line-height: 350px;
  margin-bottom: 22px;
  letter-spacing: 20px;
}
.error-text{
  margin-bottom: 7px;
}
.error-section .text{
  margin-bottom: 35px;
}
.error-btn .btn-one{
  font-size: 14px;
  text-transform: uppercase;
  padding: 4px 38px 5px 38px;
  border-radius: 5px;
}


/*** 

====================================================================
                        service-details
====================================================================

***/


.service-details{
  padding: 100px 0px 77px 0px;
}
.service-sidebar-content .categories-list{
  margin-bottom: 53px;
}
.service-sidebar-content .categories-list li a{
  position: relative;
  display: block;
  border-bottom: 1px solid #f4f4f4;
  padding: 7px 0px 8px 16px;
  transition: all 500ms ease;
}
.service-sidebar-content .categories-list li a:before{
  position: absolute;
  content: "\f0da";
  font-family: 'FontAwesome';
  color: #fff;
  font-size: 16px;
  top: 7px;
  right: 11px;
}
.service-sidebar-content .categories-list li a.active,
.service-sidebar-content .categories-list li a:hover{
  background: #6c96ff;
  border-bottom: 1px solid #6c96ff;
  border-radius: 5px;
  color: #fff;
}
.service-sidebar-content .download a{
  position: relative;
  display: block;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  background: #6c96ff;
  padding: 7px 38px 7px 17px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.service-sidebar-content .download a:hover{
  background: #222;
}
.service-sidebar-content .download i{
  float: right;
}
.service-sidebar-content .share{
  margin-top: -10px;
}
.service-sidebar-content .title h4{
  margin-bottom: 26px;
}
.service-sidebar-content .share li a{
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #6c96ff;
  border-radius: 3px;
  margin: 0px 4px;
}
.service-sidebar-content .share li a:hover{
  background: #222;
}
.service-sidebar-content .download-box{
  margin-bottom: 51px;
}
.service-details-content img{
  width: 100%;
}
.service-details-content .video-gallery{
  padding: 0px;
  margin-top: 55px;
}
.service-details-content .video-gallery .overlay-gallery{
  margin-left: 0px;
}
.service-details-content .img-box{
  margin-bottom: 33px;
}
.service-details-content .title h3{
  color: #6c96ff;
  margin-bottom: 9px;
}
.service-details-content .video-gallery .overlay-gallery {
  background-color: rgba(0, 0, 0, 0.5);
}
.service-details-content .video-gallery:hover .overlay-gallery {
  background-color: rgba(0, 0, 0, 0.5);
}
.service-details-content .video-gallery .overlay-gallery .icon-holder .icon a{
  font-size: 25px;
  height: 45px;
  width: 70px;
  line-height: 45px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 5px;
}
.service-details-content p{
  line-height: 25px;
  margin-bottom: 17px;
}
.service-details-content .content-style-one{
  display: inline-block;
  margin-bottom: 3px;
}
.service-details-content .content-style-two .title h3{
  margin-bottom: 14px;
}
.service-details-content .content-style-two p{
  margin-bottom: 0px;
}
.service-details-content .content-style-two{
  display: inline-block;
  margin-bottom: 14px;
}


/*** 

====================================================================
                        price-page
====================================================================

***/


.price-list-section{
  padding: 0px 0px 78px 0px
}
.price-list .single-price{
  position: relative;
  display: inline-block;
  background: #f7f7f7;
  width: 20%;
  margin: 0px -2px;
  border-right: 2px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
  cursor: pointer;
}
.price-content{
  position: relative;
  border: 1px solid #eaeaea;
}
.price-list-one .single-price:nth-child(5),
.price-list-two .single-price:nth-child(5),
.price-list-three .single-price:nth-child(5){
  border-right: none;
  margin: 0px -3px 0px -2px;
}
.price-list-three .single-price{
  border-bottom: none;
}
.price-list .single-price .view-content{
  position: relative;
  padding: 56px 0px 48px 0px;
}
.price-list .single-price .view-content .icon-box{
  margin-bottom: 23px;
}
.price-list .single-price .view-content .icon-box i:before{
  font-size: 55px;
  color: #222;
  margin: 0px;
}
.price-list .single-price .view-content h4{
  color: #888888;
}
.price-list .single-price .hidden-content .icon-box i:before{
  font-size: 80px;
  color: #ececec;
  margin: 0px;
}
.price-list .single-price .hidden-content h4{
  position: relative;
  top: -15px;
}
.price-list .single-price .hidden-content .icon-box{
  margin-bottom: 30px;
}
.price-list .single-price .hidden-content .price{
  position: relative;
  margin-top: -70px;
}

.price-list .single-price .hidden-content {
  position: absolute;
  top: 0px;
  left: 0px;
  background: #f7f7f7;
  width: 100%;
  padding: 56px 0px 48px 0px;
  opacity: 0;
  transition: all 900ms ease;
}
.price-list .single-price:hover .hidden-content {
  opacity: 1;
  transition: all 900ms ease;
}
.price-list-section .price-btn a{
  color: #82a5fd;
}
.price-list-section .price-btn a:hover{
  color: #222;
}
.price-list-section .price-btn{
  position: relative;
  margin-top: 23px;
}

/** self-service **/


.self-service{
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 75px 0px 67px 0px;
  background-attachment: fixed;
}
.self-service h4{
  color: #fff;
  line-height: 25px;
}
.self-service h3{
  color: #82a5fd;
  margin-bottom: 16px;
}
.self-service .right-content{
  position: relative;
  padding: 60px 0px 12px 50px;
}
.self-service .right-content:before{
  position: absolute;
  content: '';
  background: #fff;
  top: 0px;
  left: 5px;
  width: 5px;
  height: 100%;
}
.self-service .left-content{
  padding: 60px 0px 12px 0px;
}


/*** 

====================================================================
                        Blog-Page
====================================================================

***/

.blog-grid{
  padding: 92px 0px 83px 0px;
}
.blog-grid .news-column:nth-child(6) .single-news-content .lower-content h4 a{
  margin-bottom: 31px;
}
.news-btn a{
  text-decoration: underline;
}
.news-btn{
  position: relative;
  top: -2px;
}
.blog-grid .single-news-content{
  margin-bottom: 40px;
}
.blog-grid .title-text {
  margin-bottom: 55px;
}

/** blog-classic **/

.blog-classic{
  padding: 100px 0px 100px 0px;
}
.sidbar-page-container .sidebar-search .input-box{
  position: relative;
  margin-bottom: 35px;
}
.sidbar-page-container .sidebar-search input[type='search']{
  position: relative;
  width: 100%;
  height: 52px;
  line-height: 52px;
  color: #222;
  border: 2px solid #82a5fd;
  border-radius: 5px;
  padding: 0px 60px 0px 20px;
  transition: all 500ms ease;
}
.sidbar-page-container .sidebar-search input[type='search']:focus{
  border: 2px solid #222;
}
.sidbar-page-container .sidebar-search input[type='search']:focus + button,
.sidbar-page-container .sidebar-search .input-box button:hover{
  background: #222;
}
.sidbar-page-container .sidebar-search .input-box button{
  position: absolute;
  top: 0px;
  right: 0px;
  height: 52px;
  width: 56px;
  line-height: 52px;
  background: #82a5fd;
  text-align: center;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 500ms ease;
}
.sidbar-title h3{
  position: relative;
  margin-bottom: 31px;
}
.sidbar-title h3:before{
  position: absolute;
  content: '';
  background: #f7f7f7;
  height: 2px;
  width: 55px;
  left: 0px;
  bottom: -8px;
}
.sidbar-page-container .sidebar-archives{
  margin-bottom: 30px;
}
.sidbar-page-container .sidebar-archives li a{
  position: relative;
  display: block;
  margin-bottom: 5px;
  padding: 0px 0px 0px 13px;
}
.sidbar-page-container .sidebar-archives li a:before{
  position: absolute;
  content: "\f0da";
  font-size: 15px;
  font-family: 'FontAwesome';
  left: 0px;
  top: 1px;
}
.sidbar-page-container .sidbar-post .single-post{
  position: relative;
  margin-bottom: 44px;
  padding: 0px 0px 0px 120px;
}
.sidbar-page-container .sidbar-post .single-post h4,
.sidbar-page-container .sidbar-post .single-post h4 a{
  line-height: 22px;
  margin-bottom: -5px;
}
.sidbar-page-container .sidbar-post .single-post:nth-child(1){
  margin-bottom: 40px;
}
.sidbar-page-container .sidbar-post .single-post .img-box{
  position: absolute;
  overflow: hidden;
  left: 0px;
  top: -3px;
  width: 100px;
  height: 69px;
  border-radius: 5px;
}
.sidbar-page-container .sidbar-post .single-post .img-box img{
  width: 100%;
  border-radius: 5px;
  transform: scale(1.0);
  transition: all 500ms ease;
}
.sidbar-page-container .sidbar-post .single-post:hover .img-box img{
  transform: scale(1.1);
}
.sidbar-page-container .sidbar-post .single-post .text{
  font-size: 14px;
}
.sidbar-page-container .sidbar-post .sidbar-title h3{
  margin-bottom: 50px;
}
.sidebar-categories .sidbar-title h3{
  margin-top: -9px;
}
.sidbar-page-container .sidebar-categories .categories-list li a{
  position: relative;
  display: block;margin-bottom: 17px;
}
.sidbar-page-container .sidebar-categories{
  display: inline-block;
  margin-bottom: 12px;
}
.sidbar-page-container .sidebar-twitter{
  position: relative;
  margin: 0px -5px;
}
.sidbar-page-container .sidebar-twitter li{
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 115px;
  height: 85px;
  float: left;
  margin: 5px;
  border-radius: 5px;
}
.sidbar-page-container .sidebar-twitter li img{
  width: 100%;
  border-radius: 5px;
  transform: scale(1.0);
  transition: all 500ms ease;
}
.sidbar-page-container .sidebar-twitter li:hover img{
  transform: scale(1.1);
}
.default-blog-content .single-blog-content .img-box{
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 12px;
}
.default-blog-content .single-blog-content img{
  width: 100%;
  border-radius: 5px;
  transform: scale(1.0);
  transition: all 500ms ease;
}
.default-blog-content .single-blog-content:hover img{
  transform: scale(1.1);
}
.default-blog-content .single-blog-content .meta{
  margin-bottom: 10px;
}
.default-blog-content .single-blog-content .meta li{
  margin-right: 18px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.default-blog-content .single-blog-content h3 a{
  margin-bottom: 18px;
  display: block;
}
.default-blog-content .single-blog-content p{
  line-height: 25px;
}
.default-blog-content .single-blog-content{
  margin-bottom: 57px;
}
.default-blog-content .single-blog-content:nth-child(3) {
  margin-bottom: 26px;
}
.blog-classic{
  padding: 100px 0px;
}

/** blog details **/


.blog-details{
  padding: 100px 0px;
}
.blog-details-content .text-italic{
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  margin: 0px 0px 24px 28px;
  padding: 0px;
  border-left: none;
}
.blog-details-content .text-italic:before{
  position: absolute;
  content: '';
  background: #f7f7f7;
  width: 4px;
  height: 100%;
  left: -15px;
  top: 0px;
}
.blog-details-content .single-blog-content h3{
  display: block;
  margin-bottom: 18px;
}
.blog-details-content .single-blog-content:hover img{
  transform: scale(1.0);
}
.blog-details-content .single-blog-content{
  margin-bottom: 25px;
}
.blog-details-content .post-share-option{
  position: relative;
  margin: 36px 0px 30px 0px;
}
.blog-details-content .post-share-option .left-content .text,
.blog-details-content .post-share-option .left-content .post-social,
.blog-details-content .post-share-option .left-content,
.blog-details-content .post-share-option .right-content{
  display: inline-block;
}
.blog-details-content .post-share-option .left-content .text{
  margin-right: 20px;
  color: #222;
}
.blog-details-content .post-share-option .left-content .post-social li a{
  position: relative;
  display: inline-block;
  font-size: 14px;
  width: 37px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  background: #f7f7f7;
  color: #222;
  border-radius: 50px;
  margin: 0px 3px;
}
.blog-details-content .post-share-option .left-content .post-social li a:hover{
  color: #fff;
  background: #82a5fd;
}
.blog-details-content .post-share-option .right-content{
  position: relative;
  top: 6px;
  color: #222;
}
.blog-details-content .post-share-option .right-content a{
  color: #82a5fd;
}
.blog-details-content .post-share-option .right-content a:hover{
  color: #222;
}
.blog-details-content .about-author h4,
.blog-details-content .comment-area h4{
  margin-bottom: 16px;
}
.blog-details-content .about-author .author-content{
  position: relative;
  background: #f7f7f7;
  padding: 30px 22px 40px 160px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.blog-details-content .about-author .author-content .author-img,
.blog-details-content .comment-area .img-box{
  position: absolute;
  left: 38px;
  top: 40px;
}
.blog-details-content .about-author .author-content .author-name,
.blog-details-content .comment-area .comment-box .comment-name{
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  color: #222;
  margin-bottom: 7px;
}
.blog-details-content .about-author .author-content .date,
.blog-details-content .comment-area .comment-box .date{
  display: inline-block;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 7px;
}
.blog-details-content .about-author .author-content .text,
.blog-details-content .comment-area .comment-box .text{
  font-size: 14px;
  line-height: 22px;
}
.blog-details-content .comment-area .comment-box{
  position: relative;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  padding: 30px 22px 38px 160px;
  margin-bottom: 31px;
}
.blog-details-content .comment-form input[type='text'],
.blog-details-content .comment-form input[type='email'],
.blog-details-content .comment-form textarea{
  position: relative;
  width: 100%;
  height: 33px;
  line-height: 33px;
  background: #f4f4f4;
  padding: 0px 20px;
  margin-bottom: 29px;
  border: 2px solid #f4f4f4;
  transform: all 500ms ease;
}
.blog-details-content .comment-form input:focus,
.blog-details-content .comment-form textarea:focus{
  border: 2px solid #82a5fd;
  transition: all 500ms ease;
}
.blog-details-content .comment-form textarea{
  height: 241px;
  resize: none;
}
.blog-details-content .comment-form .title h3{
  display: block;
  padding-bottom: 4px;
  margin-bottom: 39px;
  border-bottom: 1px solid #e9e9e9;
}


/*** 

====================================================================
                        contact-Page
====================================================================

***/


.contact-section{
  padding-bottom: 87px;
}
.google-map-area {
  position: relative;
}
#contact-google-map {
  height: 435px;
  width: 100%;
}
.contact-section .contact-info h3{
  color: #82a5fd;;
  margin-bottom: 21px;
}
.contact-section .contact-info .info-box{
  position: relative;
  padding: 0px 0px 0px 63px;
  margin-bottom: 48px;
}
.contact-section .contact-info .icon-box{
  position: absolute;
  left: 0px;
  top: 5px;
  height: 42px;
  width: 42px;
  line-height: 42px;
  border: 1px solid #82a5fd;
  border-radius: 5px;
  text-align: center;
  transition: all 500ms ease;
}
.contact-section .contact-info .info-box:hover .icon-box{
  background: #222;
  border: 1px solid #222;
}
.contact-section .contact-info{
  position: relative;
  margin-top: 19px;
  padding-right: 20px;
}
.contact-section .contact-info .icon-box i:before{
  font-size: 20px;
  color: #82a5fd;
  transition: all 500ms ease;
}
.contact-section .contact-info .info-box:hover .icon-box i:before{
  color: #ffffff;
}
.contact-section .social-info{
  display: inline-block;
  margin-top: 16px;
}
.contact-section .social-info h4{
  color: #82a5fd;
  margin-bottom: 15px;
}
.contact-section .social-info li a{
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: #cfcfd2;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #82a5fd;
  border-radius: 50%;
  text-align: center;
  margin-right: 6px;
}
.contact-section .social-info li a:hover{
  color: #82a5fd;
}
.contact-section .contact-form-area{
  position: relative;
  padding: 29px 30px 78px 53px;
  background: #82a5fd;
  color: #fff;
  border-radius: 10px;
}
.contact-section .contact-form-area h3{
  color: #fff;
  margin-bottom: 17px;
}
.contact-section .contact-form-area .text{
  margin-bottom: 30px;
  line-height: 22px;
}
.contact-section .contact-form-area input,
.contact-section .contact-form-area textarea{
  position: relative;
  width: 100%;
  height: 33px;
  background: #fff;
  line-height: 33px;
  border-radius: 5px;
  padding: 0px 20px;
  margin-bottom: 24px;
  color: #888;
}
.contact-section .contact-form-area textarea{
  height: 119px;
  border-radius: 10px;
  margin-top: 19px;
  margin-bottom: 20px;
  resize: none;
}
.contact-section .contact-form-area button{
  /* position: relative;
  display: inline-block;
  font-size: 14px;
  color: #82a5fd; */
  background: #fff;
  /* padding: 1px 26px 1px 25px;
  border-radius: 5px;
  transition: all 500ms ease; */
}
.contact-section .contact-form-area button:hover{
  background: #222222;
  color: #ffffff;
}

/*  Theme Responsive Css */




@media only screen and (max-width: 1200px){
	
	body {
		overflow-x:hidden;
	}
	.header-top .header-info .single-info{
		margin-left: 25px;
	}
	.single-help-content{
		height: 360px;
	}
	.single-help-content{
		padding: 55px 30px 35px 30px;
	}
	.single-help-content .top-content{
		padding: 0px 0px 0px 30px;
	}
	.single-help-content.third-column .top-content{
		padding: 0px 0px 0px 35px;
	}
	.single-news-content{
		height: 480px;
	}
	.video-gallery{
		margin-top: 110px;
	}
	.header-top .header-info .top-btn{
		margin-left: 30px;
	}
	.about-section .img-box-two{
		margin-top: 75px;
	}
	.about-section .img-box-three{
		margin-top: -240px;
	}
	.logo-widget .footer-social li a{
		margin-right: 15px;
	}
	.offer-content{
		height: 330px;
	}
	.team-section .owl-theme .owl-controls .owl-nav{
		top: -45px;
	}
	.single-choose-content{
		height: 290px;
	}
	.main-slider .slider-wrapper .image img{
		width: auto;
	}
	
}



@media only screen and (max-width: 991px){
	
	.logo-box{
		display: block !important;
		margin: 15px auto;
	}
	.header-top {
	    padding: 17px 0px 25px 0px;
	}
	.single-working-content{
		margin: 20px 0px;
	}
	.single-service-content .lower-content {
	    padding: 41px 15px 33px 15px;
	}
	.single-counter-content{
		margin: 15px 0px;
	}
	.video-gallery {
	    margin-top: 40px;
	    padding-left: 0px;
	}
	.video-gallery .overlay-gallery{
		margin-left: 0px;
	}
	.main-menu{
		margin-left: 0px !important;
		width: 100%;
	}
	.search-box-area{
		position: absolute;
		float: none;
		top: 5px;
		right: 15px;
	}
	.about-section .img-box-three,
	.about-section .img-box-two{
		margin: 0px;
		left: 0px;
	}
	.about-content{
		margin-left: 0px;
		margin-top: 15px;
		margin-bottom: 30px;
	}
	.about-section .img-box-one,
	.about-section .img-box-two{
		max-width: 280px;
	}
	.single-news-content {
	    padding-bottom: 40px;
	}
	.single-help-content.fifth-column {
	    padding: 52px 70px 80px 53px;
	}
	.single-help-content.second-column {
	    padding: 55px 30px 22px 50px;
	}
	.single-pricing-content{
		margin: 15px 0px;
	}
	.offer-content-three{
		padding-bottom: 7px;
	}
	.service-sidebar-content{
		margin-bottom: 30px;
		max-width: 300px;
	}
	.sidbar-page-container .sidebar{
		margin: 30px 0px;
	}
	.contact-section .social-info{
		margin-bottom: 30px;
	}
	.header-top .header-info{
		float: none;
		text-align: center;
	}
	.service-widget{
		margin-left: 0px;
	}

}


@media only screen and (max-width: 767px){
	
	.header-top .header-info .single-info{
		margin-left: 15px;
	}
	.header-top .header-info .top-btn {
	    margin-left: 0px;
	    margin-top: 20px;
	}
	.header-top .header-info{
		float: none;
		width: 550px;
		margin: auto;
	}
	.single-service-content,
	.blog-grid .single-news-content{
		max-width: 370px;
		margin: 15px auto;
	}
	.single-pricing-content{
		max-width: 400px;
		margin: 15px auto;
	}
	.logo-widget,
	.service-widget{
		margin: 0px 0px 30px 0px;
	}
	.about-section .img-box-three{
		max-width: 280px;
	}
	.error-section .title{
		font-size: 140px;
		line-height: 140px;
		letter-spacing: 0px;
	}
	.price-list .single-price{
		width: 55%;
		max-width: 232px;
	}
	.price-list .single-price{
		border: 2px solid #eaeaea;
		margin: 5px 0px;
	}
	.price-list-one .single-price:nth-child(5), 
	.price-list-two .single-price:nth-child(5), 
	.price-list-three .single-price:nth-child(5){
		border-right: 2px solid #eaeaea;
	}
	.price-content{
		border: none;
	}
	.self-service .right-content:before{
		display: none;
	}
	.self-service .left-content{
		text-align: left;
	}
	.self-service .right-content{
		padding-left: 0px;
	}
	.single-news-content{
		height: 440px;
	}
	.single-working-content{
		max-width: 270px;
		margin: 20px auto
	}
	.single-counter-content{
		max-width: 270px;
		margin: 15px auto;
	}
	.logo-widget .footer-logo{
		margin-bottom: 30px;
	}
	
}

@media only screen and (max-width: 599px){
	
	.service-section .service-btn{
		float: none;
	}
	.header-top .header-info{
		width: 100%;
		text-align: center;
	}
	.header-top .header-info .top-btn{
		margin: 20px 0px 0px 0px;
	}
	.history-content .slider-content{
		padding: 33px 20px 104px 20px;
	}
	
}


@media only screen and (max-width: 499px){

	.apps-content .apps-box .single-apps-box{
		margin: 0px 0px 10px 0px;
	}
	.blog-details-content .post-share-option .right-content{
		float: none;
		display: block;
	}
	.owl-dots{
		display: none !important;
	}
	.header-top .header-info .top-btn{
		display: block;
	}
}

@media only screen and (max-width: 399px){

	.single-choose-content{
		height: 300px;
	}
	.single-help-content{
		height: 370px;
	}
	.single-testimonial-content{
		height: 300px;
	}
	.offer-content .offer-btn a{
		padding: 8px 27px 6px 27px;
	}

}




/** main-menu **/



@media only screen and (max-width: 991px){
    

/*=================== mobile-menu ======================*/

  .navigation {
    display: none;
  } 

  .main-menu .collapse {
    border: 0px;
    max-height: 300px;
    display: none !important;
    overflow: auto !important;
  } 

  .main-menu .collapse.in, 
  .main-menu .collapsing {
    display: block !important;
  } 

  .main-menu .navbar-header{
    padding: 8px 0px;
    float: none;
  }  

  .main-menu .navbar-toggle {
    float: none;
    margin: 0;
    display: block;
  }  

  .main-menu .navbar-toggle .icon-bar {
    background: #222;
    width: 30px;
  }  


/*=================================menu=======================*/
  
}


@media (max-width: 1200px) and (min-width: 992px) {
	.menuzord-menu > li > a {
	    padding: 40px 15px;
	}
	.theme_menu .thm-btn {
		padding: 0 15px;
	}
	.theme_menu .right-column {
	    width: 225px;
	}
}

@media only screen and (min-width: 991px){
  .mobile-menu {
    display: none;
  }      
}